<template>
  <div class="calculation section">
    <div class="container calculation__btns">
      <router-link
        :to="{ name: 'generator_plus' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-plus"></i>
      </router-link>
      <router-link
        :to="{ name: 'generator_pro' }"
        class="calculation__btns-item calculation__btns-item_sm"
      >
        <span>
          <i class="fas fa-plus"></i>
        </span>
        <span></span>
        <span>
          <i class="fas fa-minus"></i>
        </span>
      </router-link>
      <router-link
        :to="{ name: 'generator_multiply' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-times"></i>
      </router-link>
      <router-link
        :to="{ name: 'generator_divide' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-slash" style="transform: rotate(90deg)"></i>
      </router-link>
      <router-link
        :to="{ name: 'generator_root' }"
        class="calculation__btns-item"
      >
        <i class="fas fa-square-root-alt"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  name: "index",
  data() {
    return {
      sheet_data: {
        cols: [
          { name: "A", key: 0 },
          { name: "B", key: 1 },
          { name: "C", key: 2 },
        ],
        data: [
          ["id", "name", "value"],
          [1, "sheetjs", 7262],
          [2, "js-xlsx", 6969],
        ],
      },
    };
  },
  methods: {
    doIt() {
      let worksheet = XLSX.utils.aoa_to_sheet(this.sheet_data.data);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "Excel_File");
      XLSX.writeFile(new_workbook, "file.xls");
    },
  },
};
</script>
